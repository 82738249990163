import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import { H2, TextSmall } from "utils";

type FaqContent = {
  question: string;
  answer: string;
};

type Props = {
  heading: string;
  intro: string;
  faqs: FaqContent[];
};

const Faq: FC<Props> = (props) => {
  const { heading, intro, faqs } = props;

  return (
    <Styled.FaqContainer id="faq">
      <H2>{heading}</H2>

      <TextSmall>{intro}</TextSmall>

      <Styled.Faq id="faqs" defaultActiveKey="1">
        {faqs?.map((faq, i) => (
          <Styled.AccordionItem key={`faq + ${i}`} eventKey={i.toString()}>
            <Styled.AccordionHeader>{faq?.question}</Styled.AccordionHeader>
            <Styled.AccordionBody>{faq?.answer}</Styled.AccordionBody>
          </Styled.AccordionItem>
        ))}
      </Styled.Faq>
    </Styled.FaqContainer>
  );
};

Builder.registerComponent(Faq, {
  name: "FAQ",
  defaultStyles: {
    marginTop: "0",
  },
  inputs: [
    {
      name: "heading",
      type: "string",
      defaultValue: "FAQ",
    },
    {
      name: "intro",
      type: "longText",
      defaultValue: "Holy mackeral, what does all of this mean?! We’ve got you covered.",
    },
    {
      name: "faqs",
      type: "list",
      subFields: [
        {
          name: "question",
          type: "string",
        },
        {
          name: "answer",
          type: "longText",
        },
      ],
    },
  ],
});

export default Faq;
