import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import { Paragraph } from "utils";

type Props = {
  heading: string;
  description: string;
};

const About: FC<Props> = (props) => {
  const { heading, description } = props;

  return (
    <Styled.AboutWrapper id="about">
      <Styled.AboutContainer>
        <Styled.Columns>
          <Styled.LeftColumn lg="4" />
          <Styled.MiddleColumn lg="4">
            <Styled.Heading>{heading}</Styled.Heading>
            <Paragraph>{description}</Paragraph>
            <Styled.Beuno id="bueno-embed-container"></Styled.Beuno>
          </Styled.MiddleColumn>
          <Styled.RightColumn lg="4" />
        </Styled.Columns>
      </Styled.AboutContainer>
    </Styled.AboutWrapper>
  );
};

Builder.registerComponent(About, {
  name: "About",
  defaultStyles: {
    marginTop: "0",
  },
  inputs: [
    {
      name: "heading",
      type: "string",
      defaultValue: "A collection of poker-themed NFTs on the Ethereum blockchain",
    },
    {
      name: "description",
      type: "longText",
      defaultValue: "Every NFT is a unique piece of art.",
    },
  ],
});

export default About;
