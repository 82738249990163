import styled, { css } from "styled-components";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export enum Colors {
  Primary = "#000072",
  Secondary = "#0bcbff",
  Tertiary = "#0777c5",
  Orange = "#fd6c02",
  Red = "#ff01bd",
  Black = "#000000",
  White = "#ffffff",
}

export enum Gradients {
  Red = "linear-gradient(90deg, #fd6c02 0%, #ff01bd 100%);",
  Blue = "linear-gradient(180deg, #0bcbff 0%, #000072 100%);",
}

export enum FontSizes {
  Small = "0.875rem",
  Medium = "1rem",
  Large = "1.25rem",
  XLarge = "1.5rem",
  XXLarge = "2.25rem",
  XXXLarge = "3.125rem",
}

export enum LineHeights {
  Small = "1",
  Medium = "1.1",
  Large = "1.3",
  XLarge = "1.5",
}

export enum Spacers {
  XXSmall = "0.25rem",
  XSmall = "0.5rem",
  Small = "1rem",
  Medium = "1.5rem",
  Large = "2rem",
  XLarge = "3rem",
  XXLarge = "4rem",
}

export enum BreakPoints {
  Small = "576px",
  Medium = "768px",
  Large = "992px",
  XLarge = "1200px",
  XXLarge = "1400px",
}

export const H1 = styled.h1`
  margin-bottom: ${Spacers.Medium};
  font-size: ${FontSizes.XXLarge};
  line-height: ${LineHeights.Medium};

  @media screen and (min-width: ${BreakPoints.Medium}) {
    font-size: ${FontSizes.XXXLarge};
  }
`;

export const H2 = styled.h2`
  text-transform: uppercase;
  margin-bottom: ${Spacers.Medium};
  font-size: ${FontSizes.XXLarge};
  line-height: ${LineHeights.Medium};

  @media screen and (min-width: ${BreakPoints.Medium}) {
    font-size: ${FontSizes.XXXLarge};
  }
`;

export const H3 = styled.h3`
  font-size: ${FontSizes.XLarge};
  margin-bottom: ${Spacers.Medium};
`;

export const H4 = styled.h4`
  font-size: ${FontSizes.Large};
  margin-bottom: ${Spacers.Medium};
`;

export const H5 = styled.h5`
  text-transform: uppercase;
  font-size: ${FontSizes.Medium};
  margin-bottom: ${Spacers.Medium};
`;

export const H6 = styled.h6`
  text-transform: uppercase;
  font-size: ${FontSizes.Small};
  margin-bottom: ${Spacers.Medium};
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  margin-bottom: ${Spacers.Medium};
`;

export const TextLarge = styled.p`
  font-size: ${FontSizes.Large};
  margin-bottom: ${Spacers.Medium};
`;

export const TextSmall = styled.p`
  font-size: ${FontSizes.Small};
  margin-bottom: ${Spacers.Medium};
`;

export const btnCss = css`
  text-transform: uppercase;
  padding: 0.75rem 1.25rem;
  margin-top: ${Spacers.XSmall};
  border: 2px solid transparent;
  border-radius: 1.875rem;
  line-height: 1;
  width: auto;
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
  background-image: ${Gradients.Red};
  background-position: -2px;
  background-size: calc(100% + 4px) auto;

  &:hover,
  &:focus {
    color: ${Colors.Orange};
    border-color: ${Colors.Orange};
    background: ${Colors.White};
  }
`;

export const PrimaryBtn = styled(Button)`
  ${btnCss}
  background-image: ${Gradients.Red};

  &:hover,
  &:focus {
    color: ${Colors.Orange};
    border-color: ${Colors.Orange};
    background: ${Colors.White};
  }
`;

export const SecondaryBtn = styled(Button)`
  ${btnCss}
  background-color: transparent;

  &:hover,
  &:focus {
    background-image: ${Gradients.Red};
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(255, 255, 255, 0);
    border-color: ${Colors.Orange};
  }
`;

export const linkCss = css`
  display: inline-block;
  color: ${Colors.White};
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    background-image: ${Gradients.Red};
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(255, 255, 255, 0);
  }
`;

export const PrimaryHashLink = styled(HashLink)`
  ${linkCss}
`;

export const PrimaryLink = styled(Link)`
  ${linkCss}
`;
