import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import { Link } from "react-router-dom";
import { H2, Paragraph, PrimaryBtn } from "utils";

type ParagraphContent = {
  content: string;
};

type Props = {
  heading: string;
  paragraphs: ParagraphContent[];
  buttonText: string;
  buttonUrl: string;
};

const Discord: FC<Props> = (props) => {
  const { heading, paragraphs, buttonText, buttonUrl } = props;

  return (
    <Styled.DiscordWrapper id="discord">
      <Styled.DiscordContainer>
        <Styled.Columns>
          <Styled.LeftColumn lg="6">
            <H2>{heading}</H2>

            {paragraphs?.map((paragraph, i) => (
              <Paragraph key={`paragraph + ${i}`}>{paragraph?.content}</Paragraph>
            ))}

            <Link to={buttonUrl}>
              <PrimaryBtn>{buttonText}</PrimaryBtn>
            </Link>
          </Styled.LeftColumn>
          <Styled.RightColumn lg="6" />
        </Styled.Columns>
      </Styled.DiscordContainer>
    </Styled.DiscordWrapper>
  );
};

Builder.registerComponent(Discord, {
  name: "Discord",
  defaultStyles: {
    marginTop: "0",
  },
  inputs: [
    {
      name: "heading",
      type: "string",
      defaultValue: "Join The Pod",
    },
    {
      name: "paragraphs",
      type: "list",
      subFields: [
        {
          name: "content",
          type: "longText",
        },
      ],
    },
    {
      name: "buttonText",
      type: "string",
      defaultValue: "Launch Discord",
    },
    {
      name: "buttonUrl",
      type: "url",
      defaultValue: "https://discord.com",
    },
  ],
});

export default Discord;
