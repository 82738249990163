import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { BreakPoints, H3, Spacers, Colors, TextSmall } from "utils";
import BubblesTopBottom from "assets/images/bubbles-topbottom.svg";

export const EventsWrapper = styled.div`
  width: 100%;
  background-image: url(${BubblesTopBottom});
  background-attachment: fixed;
  background-position: left 5% center;
  background-repeat: no-repeat;
  background-size: 80% auto;

  @media screen and (min-width: ${BreakPoints.Large}) {
    background-size: auto 60%;
  }
`;

export const EventsContainer = styled(Container)`
  width: 100%;
  padding: ${Spacers.XXLarge} ${Spacers.Medium};
  text-align: center;
`;

export const Columns = styled(Row)`
  margin: 0;
  justify-content: space-between;
  gap: ${Spacers.XXLarge};
`;

export const Column = styled(Col)`
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding: 0;

  @media screen and (min-width: ${BreakPoints.Large}) {
    min-height: 100px;
  }
`;

export const Content = styled.div`
  padding: ${Spacers.Medium} ${Spacers.Large} ${Spacers.Small};
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 250px;
`;

export const Date = styled(TextSmall)`
  margin-bottom: ${Spacers.Small};
`;

export const Subtitle = styled(H3)`
  text-transform: uppercase;

  &::after {
    content: "";
    display: block;
    width: 50%;
    margin: ${Spacers.Small} auto;
    border-bottom: 1px solid ${Colors.White};
  }
`;
