import styled from "styled-components";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import { H2, PrimaryBtn, TextSmall, BreakPoints, H3, Spacers, Colors } from "utils";
import BubblesRight from "assets/images/bubbles-right.svg";

export const HowToWrapper = styled.div`
  width: 100%;
  padding: ${Spacers.XXLarge} ${Spacers.Medium} ${Spacers.Large};
  background-image: url(${BubblesRight});
  background-attachment: fixed;
  background-position: center right 5%;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
`;

export const HowToContainer = styled(Container)`
  position: relative;
  text-align: center;
  padding: 0;

  @media screen and (min-width: ${BreakPoints.Large}) {
    margin-left: 50%;
    text-align: left;
  }
`;

export const Title = styled(H2)`
  @media screen and (min-width: ${BreakPoints.Large}) {
    margin-left: ${Spacers.Small};
  }
`;

export const Intro = styled(TextSmall)`
  @media screen and (min-width: ${BreakPoints.Large}) {
    margin-left: ${Spacers.Small};
    width: 50%;
  }
`;

export const Button = styled(PrimaryBtn)`
  @media screen and (min-width: ${BreakPoints.Large}) {
    margin-left: ${Spacers.Small};
  }
`;

export const Steps = styled(Carousel)`
  margin: 0 0 ${Spacers.Large};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @media screen and (min-width: ${BreakPoints.Large}) {
    padding: 0 ${Spacers.Large};
    transform: translateX(-50%);
    margin-left: 0;
    margin-right: 0;
    left: unset;
    right: unset;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: ${Spacers.XLarge};
  }
`;

export const Step = styled(Carousel.Item)`
  @media screen and (min-width: ${BreakPoints.Large}) {
    &.active,
    &.carousel-item-next,
    &.carousel-item-prev {
      display: flex;
    }

    &.active.carousel-item-start,
    &.carousel-item-prev:not(.carousel-item-end) {
      transform: translateX(calc(-25% - ${Spacers.XSmall}));
    }

    &.active.carousel-item-end,
    &.carousel-item-next:not(.carousel-item-start) {
      transform: translateX(calc(25% + ${Spacers.XSmall}));
    }

    &.carousel-item-prev.carousel-item-end .row > div:not(:first-child),
    &.carousel-item-next.carousel-item-start .row > div:not(:last-child) {
      visibility: hidden;
    }
  }
`;

export const Columns = styled(Row)`
  margin: 0;
  gap ${Spacers.Large};
`;

export const Column = styled(Col)`
  background: rgba(255, 255, 255, 0.1);
  padding: ${Spacers.Large} ${Spacers.Large} ${Spacers.Small};
  text-align: center;
  min-height: 325px;

  @media screen and (min-width: ${BreakPoints.Large}) {
    min-height: 300px;
  }

  &:not(:nth-child(3)) {
    display: none;

    @media screen and (min-width: ${BreakPoints.Large}) {
      display: block;
    }
  }

  p {
    margin-bottom: 0;
  }
`;

export const Subtitle = styled(H3)`
  text-transform: uppercase;

  &::after {
    content: "";
    display: block;
    width: 50%;
    margin: ${Spacers.Small} auto;
    border-bottom: 1px solid ${Colors.White};
  }
`;

export const Image = styled.img`
  width: 350px;
  max-width: 100%;
  height: auto;

  @media screen and (min-width: ${BreakPoints.Large}) {
    position: absolute;
    right: 100%;
    bottom: -5rem;
    width: auto;
    height: 550px;
    margin: auto;
    z-index: 1;
  }
`;
