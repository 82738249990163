import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import SharksPlayingCards from "assets/images/sharks-playing-cards.png";

const Hero: FC = () => {
  return (
    <Styled.HeroWrapper>
      <Styled.HeroContainer>
        <Styled.MediaWrapper>
          <img src={SharksPlayingCards} alt="Sharks Playing Cards" />
        </Styled.MediaWrapper>
      </Styled.HeroContainer>
    </Styled.HeroWrapper>
  );
};

Builder.registerComponent(Hero, {
  name: "Hero",
  defaultStyles: {
    marginTop: "0",
  },
});

export default Hero;
