import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import { H2, TextSmall } from "utils";

type BenefitContent = {
  name: string;
  description: string;
};

type Props = {
  heading: string;
  benefits: BenefitContent[];
};

const Benefits: FC<Props> = (props) => {
  const { heading, benefits } = props;

  return (
    <Styled.BenefitsContainer id="benefits">
      <H2>{heading}</H2>
      <Styled.Columns>
        {benefits?.map((benefit, i) => (
          <Styled.Column key={`benefit + ${i}`} lg>
            <Styled.Subtitle>{benefit?.name}</Styled.Subtitle>
            <TextSmall>{benefit?.description}</TextSmall>
          </Styled.Column>
        ))}
      </Styled.Columns>
    </Styled.BenefitsContainer>
  );
};

Builder.registerComponent(Benefits, {
  name: "Benefits",
  defaultStyles: {
    marginTop: "0",
  },
  inputs: [
    {
      name: "heading",
      type: "string",
      defaultValue: "The Benefits",
    },
    {
      name: "benefits",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string",
        },
        {
          name: "description",
          type: "longText",
        },
      ],
    },
  ],
});

export default Benefits;
