import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import { HashLink } from "react-router-hash-link";
import { TextSmall } from "utils";
import MayorShark from "assets/images/mayor-shark.png";

type StepContent = {
  name: string;
  description: string;
};

type Props = {
  heading: string;
  intro: string;
  steps: StepContent[];
  buttonText: string;
  buttonUrl: string;
};

const HowTo: FC<Props> = (props) => {
  const { heading, intro, steps, buttonText, buttonUrl } = props;

  const max = 3;
  const last = steps.length - 1;
  const slides = steps?.map((step, i) => {
    const next = i + 1 > max ? i - max : i + 1;
    const previous = i - 1 < 0 ? last : i - 1;
    const start = i - 2 < 0 ? last - 1 + i : i - 2;
    return [start, previous, i, next];
  });

  return (
    <Styled.HowToWrapper id="howto">
      <Styled.HowToContainer>
        <Styled.Image src={MayorShark} alt="Mayor Shark" />

        <Styled.Title>{heading}</Styled.Title>

        <Styled.Intro>{intro}</Styled.Intro>

        <Styled.Steps interval={null}>
          {steps?.map((step, i) => (
            <Styled.Step key={`step + ${i}`}>
              <Styled.Columns>
                {slides[i]?.map((index, j) => (
                  <Styled.Column key={`col + ${i}${j}`}>
                    <Styled.Subtitle>
                      Step {index + 1}:
                      <br />
                      {steps[index].name}
                    </Styled.Subtitle>
                    <TextSmall>{steps[index].description}</TextSmall>
                  </Styled.Column>
                ))}
              </Styled.Columns>
            </Styled.Step>
          ))}
        </Styled.Steps>

        <HashLink href={buttonUrl}>
          <Styled.Button>{buttonText}</Styled.Button>
        </HashLink>
      </Styled.HowToContainer>
    </Styled.HowToWrapper>
  );
};

Builder.registerComponent(HowTo, {
  name: "How To",
  defaultStyles: {
    marginTop: "0",
  },
  inputs: [
    {
      name: "heading",
      type: "string",
      defaultValue: "How To Mint",
    },
    {
      name: "intro",
      type: "longText",
      defaultValue: "You need an Ethereum wallet to get started.",
    },
    {
      name: "steps",
      type: "list",
      subFields: [
        {
          name: "name",
          type: "string",
        },
        {
          name: "description",
          type: "longText",
        },
      ],
    },
    {
      name: "buttonText",
      type: "string",
      defaultValue: "Mint Your Card Shark",
    },
    {
      name: "buttonUrl",
      type: "url",
      defaultValue: "https://web3.com",
    },
  ],
});

export default HowTo;
