import { FC } from "react";
import { Container, Nav, Offcanvas } from "react-bootstrap";
import { PageRoutes, SocialRoutes } from "utils/routes";
import * as Images from "assets/images";
import * as Styled from "./styles";

const Header: FC = () => {
  return (
    <Styled.Header>
      <Styled.HeaderNavbar collapseOnSelect={true} key="expand" expand="lg" className="navbar-dark">
        <Container>
          <Styled.NavbarBrand>
            <Nav.Link href={PageRoutes.Home}>
              <Images.Logo />
            </Nav.Link>
          </Styled.NavbarBrand>

          <Styled.NavbarToggle aria-controls="headerNav" />

          <Styled.NavbarOffcanvas
            id="headerNav"
            aria-labelledby="headerNavLabel"
            placement="top"
            scroll={true}
            restoreFocus={false}
          >
            <Offcanvas.Header closeButton closeVariant="white">
              <Styled.NavbarTitle id="headerNavLabel">
                <Nav.Link href={PageRoutes.Home}>
                  <Images.Logo />
                </Nav.Link>
              </Styled.NavbarTitle>
            </Offcanvas.Header>
            <Styled.NavbarBody>
              <Styled.NavbarNav>
                <Styled.NavbarLink href={PageRoutes.About}>About</Styled.NavbarLink>
                <Styled.NavbarLink href={PageRoutes.Benefits}>Benefits</Styled.NavbarLink>
                <Styled.NavbarLink href={PageRoutes.Discord}>Join The Pod</Styled.NavbarLink>
                <Styled.NavbarLink href={PageRoutes.Faq}>Faq</Styled.NavbarLink>
                <Styled.NavbarLink href={PageRoutes.Events}>Events</Styled.NavbarLink>
              </Styled.NavbarNav>

              <Styled.SocialNav>
                <Styled.SocialLink href={SocialRoutes.Discord} target="_blank">
                  <Images.Discord />
                </Styled.SocialLink>
                <Styled.SocialLink href={SocialRoutes.Instagram} target="_blank">
                  <Images.Instagram />
                </Styled.SocialLink>
                <Styled.SocialLink href={SocialRoutes.Twitter} target="_blank">
                  <Images.Twitter />
                </Styled.SocialLink>
              </Styled.SocialNav>

              <Styled.ButtonNav>
                <Nav.Link href={PageRoutes.About}>
                  <Styled.ButtonLink>Connect Wallet</Styled.ButtonLink>
                </Nav.Link>
              </Styled.ButtonNav>
            </Styled.NavbarBody>
          </Styled.NavbarOffcanvas>
        </Container>
      </Styled.HeaderNavbar>
    </Styled.Header>
  );
};

export default Header;
