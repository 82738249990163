import styled from "styled-components";
import { Container } from "react-bootstrap";
import { BreakPoints } from "utils";
import Grid from "assets/images/grid.svg";
import Bubbles from "assets/images/bubbles.svg";

export const HeroWrapper = styled.div`
  width: 100%;
  background-image: url(${Grid});
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: auto 55%;
`;

export const HeroContainer = styled.div`
  width: 100%;
  background-image: url(${Bubbles});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: 20% 10%;
  background-size: 90% auto;

  @media screen and (min-width: ${BreakPoints.Large}) {
    background-position: center;
    background-size: 90% auto;
  }
`;

export const MediaWrapper = styled(Container)`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 100%;
    height: auto;
  }
`;
