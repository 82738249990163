import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { BreakPoints, H3, Spacers, Colors } from "utils";

export const BenefitsContainer = styled(Container)`
  padding: ${Spacers.XXLarge} ${Spacers.Medium} 0;
  text-align: center;
`;

export const Columns = styled(Row)`
  margin: 0 0 ${Spacers.Large};
  justify-content: space-between;
  gap: 2rem;
`;

export const Column = styled(Col)`
  background: rgba(255, 255, 255, 0.1);
  padding: ${Spacers.Large} ${Spacers.Medium} ${Spacers.Medium};

  @media screen and (min-width: ${BreakPoints.Large}) {
    min-height: 100px;
    padding: ${Spacers.XLarge} ${Spacers.Large} ${Spacers.Large};
  }
`;

export const Subtitle = styled(H3)`
  text-transform: uppercase;

  &::after {
    content: "";
    display: block;
    width: 50%;
    margin: ${Spacers.Small} auto;
    border-bottom: 1px solid ${Colors.White};
  }
`;
