import styled from "styled-components";
import { Accordion, Container } from "react-bootstrap";
import { Spacers, Colors, Gradients } from "utils";
import Chevron from "assets/images/chevron.svg";

export const FaqContainer = styled(Container)`
  padding: ${Spacers.XXLarge} ${Spacers.Medium} 0;
  text-align: center;
`;

export const Faq = styled(Accordion)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding-bottom: ${Spacers.Large};
`;

export const AccordionItem = styled(Accordion.Item)`
  width: 100%;
  background: transparent;
  color: ${Colors.White};
  border: none;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background: ${Gradients.Red};
  }
`;

export const AccordionHeader = styled(Accordion.Header)`
  width: inherit;

  .accordion-button {
    width: inherit;
    font-weight: 600;
    color: ${Colors.White};
    background: transparent;
    text-align: left;
    box-shadow: none;
    padding: ${Spacers.Medium} ${Spacers.XSmall} ${Spacers.Small};

    &::after {
      background-image: url(${Chevron});
      background-size: 80%;
      background-position: center;
    }

    &:not(.collapsed) {
      color: ${Colors.White};
      background: transparent;
    }
  }
`;

export const AccordionBody = styled(Accordion.Body)`
  padding: 0 ${Spacers.XSmall} ${Spacers.Small};
`;
