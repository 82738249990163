import { FC } from "react";
import { VentureStudio } from "assets/images";
import * as Styled from "./styles";

const Footer: FC = () => {
  return (
    <Styled.Footer>
      <VentureStudio />
    </Styled.Footer>
  );
};

export default Footer;
