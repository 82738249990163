import { FC } from "react";
import { Home } from "pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PageRoutes } from "utils";

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PageRoutes.About} element={<Home />} />
        <Route path={PageRoutes.Benefits} element={<Home />} />
        <Route path={PageRoutes.Discord} element={<Home />} />
        <Route path={PageRoutes.Events} element={<Home />} />
        <Route path={PageRoutes.Faq} element={<Home />} />
        <Route path={PageRoutes.Home} element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
