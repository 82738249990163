import styled from "styled-components";
import { Container, Col } from "react-bootstrap";
import Grid2D from "assets/images/grid-2d.svg";
import Bubbles from "assets/images/bubbles.svg";
import BubblesTop from "assets/images/bubbles-top.svg";
import GreatWhite from "assets/images/great-white.png";
import { BreakPoints, Spacers } from "utils";

export const DiscordWrapper = styled.div`
  width: 100%;
  background-image: url(${Grid2D});
  background-position: left bottom;
  background-repeat: repeat-x;
  background-size: auto 100%;
  margin-top: ${Spacers.XXLarge};
`;

export const DiscordContainer = styled.div`
  width: 100%;
  padding: ${Spacers.XLarge} 0 ${Spacers.Large};
  background-image: url(${Bubbles});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: left 10% center;
  background-size: 80% auto;

  @media screen and (min-width: ${BreakPoints.Large}) {
    background-image: url(${BubblesTop});
    background-position: right 20% center;
    background-size: auto;
  }
`;

export const Columns = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 0 ${Spacers.Medium};

  @media screen and (min-width: ${BreakPoints.Large}) {
    flex-direction: row;
  }
`;

export const LeftColumn = styled(Col)`
  padding: 0;
`;

export const RightColumn = styled(Col)`
  background-image: url(${GreatWhite});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 350px;

  @media screen and (min-width: ${BreakPoints.Large}) {
    background-position: center;
    background-size: 100% auto;
    min-height: 500px;
  }
`;
