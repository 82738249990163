import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { H2, BreakPoints, Spacers, Colors, btnCss } from "utils";
import Hammerhead from "assets/images/hammerhead.png";
import Megladon from "assets/images/megladon.png";
import HammerheadMobile from "assets/images/hammerhead-mobile.png";
import MegladonMobile from "assets/images/megladon-mobile.png";

export const AboutWrapper = styled.div`
  width: 100%;
  background-color: ${Colors.Tertiary};
  padding-top: 4rem;
`;

export const AboutContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media screen and (min-width: ${BreakPoints.Large}) {
    width: 100%;
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Columns = styled(Row)`
  margin: 0;
`;

export const LeftColumn = styled(Col)`
  background-image: url(${HammerheadMobile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: 400px;

  @media screen and (min-width: ${BreakPoints.Large}) {
    background-image: url(${Hammerhead});
    background-position: left bottom;
    background-size: auto;
    min-height: 700px;
  }
`;

export const MiddleColumn = styled(Col)`
  padding: ${Spacers.Medium};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${BreakPoints.Large}) {
    padding: ${Spacers.XLarge} ${Spacers.Medium};
  }
`;

export const RightColumn = styled(Col)`
  background-image: url(${MegladonMobile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  min-height: 350px;

  @media screen and (min-width: ${BreakPoints.Large}) {
    background-image: url(${Megladon});
    background-position: right bottom;
    background-size: auto;
    min-height: 700px;
  }
`;

export const Heading = styled(H2)`
  text-transform: none;
`;

export const Beuno = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .bueno__connect-button {
    ${btnCss};
  }
`;
