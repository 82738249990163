import styled from "styled-components";
import { Gradients, FontSizes, Colors } from "utils";

export const Wrapper = styled.div`
  color: ${Colors.White};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${Gradients.Blue};
  font-size: ${FontSizes.Medium};
`;

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .builder-component {
    width: 100%;
  }
`;
