import { FC, Fragment, useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import Layout from "layout";
import { BuilderApi } from "utils";

builder.init(BuilderApi.Key);

const Home: FC = () => {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState<BuilderComponent>();

  const fetchContent = async (): Promise<void> => {
    const data = await builder.get("page", { url: window.location.pathname }).promise();

    setContent(data);
    setNotFound(!data);
  };

  useEffect(() => {
    fetchContent();
  }, [window.location.pathname]);

  if (notFound && !isPreviewingInBuilder) {
    return <Fragment />;
  }

  return (
    <Layout>
      <BuilderComponent model="page" content={content} />
    </Layout>
  );
};

export default Home;
