import styled from "styled-components";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { PrimaryBtn, BreakPoints, Colors, Spacers, FontSizes, linkCss } from "utils";
import MenuIcon from "assets/images/menu.svg";

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderNavbar = styled(Navbar)`
  width: 100%;
`;

export const NavbarBrand = styled(Navbar.Brand)`
  max-width: 200px;

  @media screen and (min-width: ${BreakPoints.Large}) {
    max-width: unset;
  }

  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const NavbarToggle = styled(Navbar.Toggle)`
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  border: none;
  margin-right: 0 !important;
  padding: 0;

  .navbar-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(${MenuIcon});
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const NavbarOffcanvas = styled(Navbar.Offcanvas)`
  background-color: ${Colors.Primary};
  min-height: 100vh;

  @media screen and (min-width: ${BreakPoints.Large}) {
    min-height: unset;
  }
`;

export const NavbarTitle = styled(Offcanvas.Title)`
  max-width: 200px;

  svg {
    max-width: 100%;
    height: auto;
  }
`;

export const NavbarBody = styled(Offcanvas.Body)`
  padding: ${Spacers.Small} ${Spacers.Medium};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${BreakPoints.Large}) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: ${Spacers.Small};
  }
`;

export const NavbarNav = styled(Nav)`
  margin: ${Spacers.Small} auto ${Spacers.Medium};

  @media screen and (min-width: ${BreakPoints.Large}) {
    gap: 0;
    margin: auto;
  }

  @media screen and (min-width: ${BreakPoints.XLarge}) {
    padding-left: ${Spacers.Medium};
  }

  @media screen and (min-width: ${BreakPoints.XXLarge}) {
    gap: ${Spacers.XSmall};
  }
`;

export const NavbarLink = styled(Nav.Link)`
  ${linkCss}

  font-size: ${FontSizes.Large};
  color: ${Colors.White};
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;

  @media screen and (min-width: ${BreakPoints.Large}) {
    font-size: ${FontSizes.Medium};
  }
`;

export const SocialNav = styled(Nav)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${Colors.White};
  margin-top: ${Spacers.Small};
  padding-top: ${Spacers.Small};

  @media screen and (min-width: ${BreakPoints.Large}) {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`;

export const SocialLink = styled(Nav.Link)`
  padding: ${Spacers.Small};

  svg {
    width: auto;
    height: 1.25rem;
  }
`;

export const ButtonNav = styled(Nav)`
  flex-direction: row;
  justify-content: center;
  margin: auto auto ${Spacers.Large};

  @media screen and (min-width: ${BreakPoints.Large}) {
    margin: 0;
  }
`;

export const ButtonLink = styled(PrimaryBtn)`
  margin-top: 0;
  text-decoration: none;
`;
