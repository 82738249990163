import { FC } from "react";
import { Builder } from "@builder.io/react";
import * as Styled from "./styles";
import { H2, TextSmall } from "utils";

type EventContent = {
  image: string;
  date: string;
  name: string;
  description: string;
};

type Props = {
  heading: string;
  intro: string;
  events: EventContent[];
};

const Events: FC<Props> = (props) => {
  const { heading, intro, events } = props;

  return (
    <Styled.EventsWrapper id="events">
      <Styled.EventsContainer>
        <H2>{heading}</H2>

        <TextSmall>{intro}</TextSmall>

        <Styled.Columns>
          {events?.map((event, i) => (
            <Styled.Column key={`event + ${i}`} lg>
              <Styled.Image src={event?.image} alt={event?.name} />
              <Styled.Content>
                <Styled.Date>{new Date(event?.date)?.toDateString()}</Styled.Date>
                <Styled.Subtitle>{event?.name}</Styled.Subtitle>
                <TextSmall>{event?.description}</TextSmall>
              </Styled.Content>
            </Styled.Column>
          ))}
        </Styled.Columns>
      </Styled.EventsContainer>
    </Styled.EventsWrapper>
  );
};

Builder.registerComponent(Events, {
  name: "Events",
  defaultStyles: {
    marginTop: "0",
  },
  inputs: [
    {
      name: "heading",
      type: "string",
      defaultValue: "Events",
    },
    {
      name: "intro",
      type: "longText",
      defaultValue: "Proin ornare tempus velit nisi tellus luctus.",
    },
    {
      name: "events",
      type: "list",
      subFields: [
        {
          name: "image",
          type: "file",
        },
        {
          name: "date",
          type: "date",
        },
        {
          name: "name",
          type: "string",
        },
        {
          name: "description",
          type: "longText",
        },
      ],
    },
  ],
});

export default Events;
