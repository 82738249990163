export enum PageRoutes {
  About = "/#about",
  Benefits = "/#benefits",
  Discord = "/#discord",
  Events = "/#events",
  Faq = "/#faq",
  Home = "/",
}

export enum SocialRoutes {
  Discord = "https://discord.com",
  Instagram = "https://instagram.com",
  Twitter = "https://twitter.com",
}

export enum RemoteRoutes {
  Discord = "/",
}
